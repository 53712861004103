.travel-insurance {
  margin-top: 24px;

  @include respond(medium) {
    max-width: 663px;
  }

  &__date-pickers {
    margin-bottom: 24px;
    @include respond(medium) {
      display: flex;
      gap: 27px;
      margin-bottom: 32px;
    }

    .pa-react-datepicker-wrapper {
      &:first-child {
        margin-bottom: 24px;
        @include respond(medium) {
          margin: 0;
        }
      }
    }
  }

  .bx--form-item {
    margin-bottom: 24px;

    @include respond(medium) {
      margin-bottom: 32px;
    }

    .bx--radio-button-wrapper:not(:last-of-type) {
      margin-right: 24px;
    }
  }

  .holder-container {
    display: grid;
    row-gap: 24px;
    @include border-bottom;
    margin-bottom: 24px;

    @include respond(medium) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 27px;
      padding-bottom: 24px;
    }

    button {
      grid-row: 6;
      background: none;
      border: none;

      @include respond(medium) {
        margin-bottom: 0;
        grid-area: 1/2/2/3;
        justify-self: start;
      }

      p {
        @extend .body-short-02;
        color: $han-blue-70;
        grid-row: 10;
        margin-bottom: 24px;
      }
    }

    &__info {
      div:first-child {
        @extend .body-short-02;
        margin-bottom: 8px;
      }

      div:last-child {
        @extend .productive-heading-02;
      }
    }
  }

  form {
    @include respond(medium) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 27px;
    }

    h3 {
      @include respond(medium) {
        grid-column: 1/3;
      }
    }

    .form-group {
      margin-bottom: 20px;

      .pa-text-input__field-outer-wrapper
        .pa-text-input__field-wrapper
        .bx--tooltip__trigger {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;

        svg {
          width: 20px;
          height: 20px;
        }

        @include respond(medium) {
          .icn {
            width: 20px;
            height: 20px;
            padding: 0;
          }
        }
      }
    }

    &.form-client > .form-group:nth-child(9),
    &.form-holder > .form-group:nth-child(8) {
      .pa-text-input__field-wrapper .pa-input-checkmark-filled-icon {
        right: 3rem;
      }
      margin-bottom: 24px;
      @include respond(medium) {
        margin-bottom: 28px;
      }
    }

    button {
      @include respond(medium) {
        grid-column-start: 2;
        justify-self: end;
      }
    }
  }

  h3 {
    @extend .productive-heading-02;

    @include respond(medium) {
      @include fontSize(20px);
      @include lineheight(28px);
      @include letterspacing(0.16px);
    }
  }
  .bx--form-item {
    .bx--label {
      margin-bottom: 12px;
    }
  }
  .btn.btn-default {
    width: 100%;
    @include respond(medium) {
      width: 318px;
    }
  }
}

.travel-insurance__policy {
  margin-top: 24px;

  .pa-react-datepicker-wrapper {
    margin-bottom: 20px;

    @include respond(medium) {
      margin: 0;
    }
  }

  @include respond(medium) {
    max-width: 663px;
  }

  &.mt {
    @include respond(medium) {
      margin-top: 4px;
    }
  }
  .pa-default-dropdown.bx--dropdown--disabled {
    border: none;
  }

  .insurance-company {
    &__info {
      margin-bottom: 24px;
      color: $text-02;
    }
  }

  .provider {
    width: 100% !important;
    display: flex;
    flex-direction: column;

    @include respond(medium) {
      justify-content: space-between;
      border: none;
    }

    &:has(.selection) {
      @include respond(medium) {
        flex-direction: row;
        gap: 24px;
        flex-wrap: wrap;
        justify-content: unset;
        align-items: start;
      }
    }

    &--child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 147px;

      &.selection {
        &:not(:first-child) {
          padding-top: 16px;
          @include respond(medium) {
            padding-top: 0;
          }
        }

        &:not(:last-child) {
          @include border-bottom;
          padding-bottom: 16px;
          @include respond(medium) {
            border: none;
            padding-bottom: 0;
          }
        }

        .provider--child__info {
          @include respond(medium) {
            flex-direction: column;
            gap: 12px;
          }
        }

        .provider__text {
          @include respond(medium) {
            align-self: flex-start;
          }
        }
      }

      &__info {
        display: flex;
        align-items: start;
        gap: 24px;

        .pa-block__favorites-in {
          height: 63px;
          width: 136px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $ui-03;
          border-radius: 10px;
          overflow: hidden;
          transition: all 0.3s linear;

          @include respond(medium) {
            height: 95px;
            width: 204px;
          }

          img {
            width: 80%;
          }
        }

        .provider__text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 6px;

          h3 {
            @extend .button-and-link-01;

            @include respond(medium) {
              @include fontSize(16px);
              @include fontFamily('LB-Nu-Medium');
            }
          }

          p {
            @extend .helper-text-01;
            margin: 0;

            @include respond(medium) {
              @include fontSize(14px);
              @include fontFamily('LB-Nu-Medium');
            }
          }
        }
      }

      button {
        height: 24px;
        border: none;
        background: none;
        display: flex;
        align-items: start;
        gap: 8px;

        p {
          display: none;
          padding: 0;
          @include respond(medium) {
            @include fontSize(14px);
            @include fontFamily('LB-Nu-Semibold');
            @include lineheight(18px);
            @include letterspacing(0.16px);
            color: $text-light-01;
            display: block;
          }
        }
      }
    }

    p {
      @extend .body-long-01;
      @include respond(medium) {
        @include fontSize(14px);
        @include lineheight(22px);
        @include letterspacing(0.16px);

        &.border {
          @include border-bottom;

          @include respond(medium) {
            padding-bottom: 12px;
          }
        }
      }
    }
  }

  .bx--dropdown__wrapper {
    .bx--dropdown {
      //background-color: transparent;
      //outline: none !important;
      //box-shadow: none !important;

      .bx--list-box__label {
        @extend .body-short-02;
      }
    }
  }

  .additional-risk-container {
    min-height: 290px;
    position: relative;

    @include respond(small) {
      min-height: 272px;
    }

    @include respond(medium) {
      min-height: 218px;
    }

    &__loader {
      @include absCenter;
    }
  }

  .additional-risk {
    margin-bottom: 12px;
    @include border-bottom;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    padding-bottom: 12px;

    @include respond(medium) {
      justify-content: center;
    }

    .bx--form-item {
      margin-bottom: 0;

      .bx--toggle-input__label {
        max-width: calc(
          100% - 50px
        ); // Adjusts label width to prevent overlap with toggle switch
        width: 100%; // Adjust clickable part

        @include respond(medium) {
          min-height: 30px;
          display: flex;
          justify-content: center;
          max-width: calc(100% - 100px);
        }

        .bx--toggle__switch {
          position: absolute;
          right: 0;
          top: calc(50% - 6px);
          margin-top: 0;
          transform: translateY(-50%);
        }
      }
    }

    &__amount {
      @extend .productive-heading-01;
      line-height: 24px;
      @include respond(medium) {
        font-size: 16px;
        position: absolute;
        right: 64px;
      }
    }
  }

  .total-price {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include respond(medium) {
      position: relative;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      padding-right: 64px;
      gap: 12px;
    }

    &__title {
      @extend .body-long-01;
      color: $neutral-60;

      @include respond(medium) {
        font-size: 14px;
        letter-spacing: 0.16px;
      }
    }

    &__amount {
      @extend .productive-heading-03;
      color: $text-01;
    }
  }

  .btn {
    @include respond(medium) {
      // width: 318px;
      // margin-left: 345px;
    }
  }

  .inputs-container {
    @include respond(medium) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 27px;
    }

    .insurance-buyer {
      // @include border-bottom;
      padding-bottom: 0;
      // margin-bottom: 16px;

      @include respond(medium) {
        grid-column: 1/-1;
        padding-bottom: 0;

        .pa-default-dropdown__wrapper {
          width: 318px;
        }
      }
    }

    .bx--form-item {
      margin-bottom: 1.5rem;
      @include respond(medium) {
        margin-bottom: 0;
      }
    }
  }

  .form-group {
    .pa-default-dropdown__wrapper.currency-limit {
      .tooltips-wrapper {
        position: absolute;
        z-index: 1;
      }

      .bx--tooltip__label {
        margin: 0;
      }

      .bx--tooltip__trigger {
        margin: 0;

        .icn {
          padding: 0;
          width: 16px;
          height: 16px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .bx--dropdown.pa-default-dropdown {
      width: auto;
    }
  }

  .bx--modal-container {
    a {
      color: $han-blue-60;
    }
  }

  h3 {
    @extend .productive-heading-02;
    color: $text-light-01;
  }

  form {
    @include respond(medium) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 27px;
    }

    h3 {
      @include respond(medium) {
        grid-column: 1/3;
      }
    }

    .form-group {
      margin-bottom: 20px;

      .pa-text-input__field-outer-wrapper
        .pa-text-input__field-wrapper
        .bx--tooltip__trigger {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;

        svg {
          width: 20px;
          height: 20px;
        }

        @include respond(medium) {
          .icn {
            width: 20px;
            height: 20px;
            padding: 0;
          }
        }
      }
    }

    &.form-client > .form-group:nth-child(9),
    &.form-holder > .form-group:nth-child(8) {
      .pa-text-input__field-wrapper .pa-input-checkmark-filled-icon {
        right: 3rem;
      }
      margin-bottom: 24px;
      @include respond(medium) {
        margin-bottom: 28px;
      }
    }

    button {
      @include respond(medium) {
        grid-column-start: 2;
        justify-self: end;
      }
    }
  }
}

.travel-insurance-confirmation {
  margin-top: 24px;

  @include respond(medium) {
    display: grid;
    grid-template-columns: 55.4fr 44.6fr;
    padding-right: 64px;

    .insurance-buyer-info {
      grid-row: 2/3;
    }
  }

  h3 {
    margin-bottom: 16px;
    @extend .productive-heading-03;
    color: $text-light-01;
  }

  label {
    @extend .body-short-02;
    margin-bottom: 0.5rem;
    display: inline-block;
    color: $text-light-02;
  }

  p {
    @extend .productive-heading-02;
    @extend .text-light-01;
  }

  .row {
    @include border-bottom;

    @include respond(medium) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .pa-method-payment {
    @include respond(medium) {
      grid-column-start: 2;
      padding-top: 32px;
      border-top: 1px solid $soft-black-border;
      grid-row: 3;
    }

    .pa-basket-pay {
      margin-bottom: 48px;
      @include respond(medium) {
        margin-bottom: 32px;
      }

      .mb-10 {
        div {
          @extend .productive-heading-01;

          @include respond(medium) {
            @include fontSize(16px);
            @include fontFamily('LB-Nu-Semibold');
            @include lineheight(24px);
            @include letterspacing(0);
          }
        }

        &:last-child {
          div {
            @include respond(medium) {
              @include fontSize(20px);
              @include fontFamily('LB-Nu-Semibold');
              @include lineheight(28px);
              @include letterspacing(0.16px);
            }
          }
        }
      }
    }

    .pa-basket-commission {
      @include respond(medium) {
        display: flex;
        gap: 16px;
        align-items: center;
      }
      .bx--dropdown {
        @include respond(medium) {
          width: 300px;
        }
      }

      &.pa-insurance-commission {
        .bx--dropdown {
          @include respond(medium) {
            width: 100%;
          }
        }
      }
    }
  }

  .form-group.insurance-buyer {
    margin-bottom: 0;
  }

  .provider-price {
    width: fit-content;
    .loader-out.btn-loader {
      top: 28% !important;
      width: fit-content;
    }
  }

  .btn.btn-refetch {
    width: fit-content !important;
    margin-left: 0 !important;
    padding: 0 !important;
    @extend .productive-heading-01;
  }
  .pa-provider-tooltip {
    width: 304px !important;
    padding: 16px !important;
    background-color: #1f1f23 !important;
    border-radius: 6px !important;
    z-index: 1000;
    opacity: 1 !important;

    @include respond(medium) {
      top: 35% !important ;
    }
  }
}

.price-loader{
  top:11% !important
}