.bx--modal-container {
  border-radius: 6px;
  background-color: $background-white;
  box-shadow: $shadow-24-white;
  width: 92%;
  height: auto;
  margin: 12px;
  top: auto;

  @include respond(medium) {
    margin: 0;
    &.bx--modal-container--sm {
      width: 31.25%;
    }
  }
}
.bx--modal-content--overflow-indicator {
  display: none;
}

.bx--modal-content {
  padding: 16px 16px 0;
  margin-bottom: 2rem;
  h3 {
    @include fontSize(16px);
    @include fontFamily('LB-Nu-Semibold');
    @include lineheight(24px);
    @include letterspacing(0);
    padding-bottom: 24px;
    text-align: center;
    @include respond(medium) {
      @include fontSize(20px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(28px);
      @include letterspacing(0.16px);
      padding-bottom: 32px;
      text-align: left;
    }
  }
  @include respond(medium) {
    padding: 32px 32px 0;
    margin-bottom: 3rem;
  }
}
.pa-modal {
  .bx--modal-content {
    padding: 24px 24px 0;
    margin-bottom: 1.5rem;
    h3 {
      padding-bottom: 16px;
    }
    @include respond(medium) {
      padding: 32px 32px 0;
      margin-bottom: 2rem;
    }
  }
  .bx--modal-footer {
    border-top: 1px solid $ui-04;
    .btn-one {
      flex: 0 1 auto;
      padding-bottom: 1rem;
      padding-right: 1rem;
      justify-content: center;
      &.btn-secondary {
        &:hover {
          color: $text-01 !important;
        }
      }
    }
  }
}
.pa-mail-modal {
  .bx--modal-footer {
    border-top: 1px solid $ui-04;
    .btn-one {
      flex: 0 1 auto;
      padding-bottom: 1rem;
      padding-right: 1rem;
      justify-content: center;
      &.btn-secondary {
        &:hover {
          color: $text-01 !important;
        }
      }
    }
  }
}

.pa-mail-modal {
  .form-group {
    margin-bottom: 0;
    margin-top: 24px;
    @include respond(medium) {
      margin-top: 32px;
    }
  }
}

.bx--body--with-modal-open {
  padding-right: 6px;
  .pa-header-inside__top {
    padding-right: 6px;
  }
}

.pa-insurance-modal {
  .bx--modal-container {
    max-width: 480px;
    @include fontSize(14px);
    @include lineheight(20px);
    @include letterspacing(0.16px);
  }

  .bx--modal-content {
    padding: 24px 16px 0 16px;
    margin-bottom: 16px;
    @include respond(medium) {
      padding: 32px 32px 0;
      margin-bottom: 2rem;
    }
  }

  .bx--modal-footer {
    .btn-one {
      &.btn-secondary {
        @extend .body-short-02;
        background-color: transparent;
        border: none;
      }

      &.button-primary {
        color: $han-blue-60;
        background-color: transparent;

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

.sms-code-modal {
  .bx--modal-container {
    width: 84%;
    @include respond(medium) {
      width: 600px;
    }
  }
  .bx--modal-content {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }
  .pa-modal-cont {
    padding: 0;
    h4 {
      padding-top: 8px;
      @include respond(medium) {
        padding-top: 16px;
        @include fontSize(28px);
      }
    }
    h2 {
      padding: 0 16px;
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
      padding-bottom: 12px;
      @include respond(medium) {
        padding: 0 0 34px;
        margin-bottom: 0;
        
      }
    }
  }
  .loader-out.btn-loader {
    top: 0px !important;
  }

  .btn-newsms {
    color: $han-blue-60;
    padding-top: 0;
    padding-bottom: 10px;
    @include respond(medium) {
      padding-bottom: 48px;
      padding-top: 24px;
    }
    .loader-out.btn-loader {
      top: -12px !important;
    }
  }
  .pa-form-block {
    &:disabled {
      .button-and-link-01 {
        opacity: 0.5;
        pointer-events: none;
      }
      .pa-modal-footer {
        .btn-close {
          color: #151519 !important;
        }
        // &:hover {
        //   font-weight: normal;
        // }
      }
    }
  }

  .button-and-link-01 {
    border: none;
    background: no-repeat;
  }

  .nob-form-block {
    margin-bottom: 24px;
    @include respond(medium) {
      margin-bottom: 48px;
    }
  }

  .pincode-input-container {
    .pincode-input-text {
      width: 50px !important;
      height: 74px !important;
      border-radius: 8px;
      border: 0.5px solid rgba(176, 176, 181, 1) !important;
      opacity: 1;
      background-color: rgba(255, 255, 255, 1) !important;
      margin-right: 16px !important;
      @include fontSize(32px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(40px);
      @include letterspacing(0);

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  .btn-modal {
    background-color: $background-white;
    height: 4rem;
    border-radius: 0;
    width: 50%;
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.16px);
    font-weight: 500;
    border-radius: 0 0 14px 14px;
    &:disabled {
      color: rgba(207, 207, 211, 1) !important;
    }

    &.btn-close {
      border-bottom-right-radius: 0;
    }

    &.active {
      font-weight: 700;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
