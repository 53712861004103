.Toastify__toast-container {
  width: auto !important;
  // min-width: 288px;
  z-index: 10000 !important;

  @include respond(medium) {
    width: 380px !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: $support-error-01 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $support-success-03 !important;
}
.Toastify__toast {
  padding: 16px !important;
  border-radius: 6px !important;
  margin-bottom: 10px !important;
  min-height: 52px !important;
  //max-height: 52px !important;
}
.Toastify__toast-body {
  padding: 0 !important;
}
.Toastify__toast-body > div:last-child {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(16px);
  @include letterspacing(0.24px);
  padding-top: 4px;
  @include respond(medium) {
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Semibold');
    @include lineheight(20px);
    @include letterspacing(0.16px);
  }
}
.Toastify__toast-icon {
  margin-inline-end: 12px !important;
  width: 20px;

  @include respond(medium) {
    margin-inline-end: 16px !important;
    width: 24px;
  }
}
.Toastify__close-button {
  align-self: auto !important;
  opacity: 1 !important;
  margin-left: 22px;
  margin-right: 6px;
  margin-top: 2px;
}

.Toastify__toast-container--bottom-left {
  left: 1rem !important;
  right: 1rem !important;
  bottom: 2rem !important;

  @include respond(medium) {
    left: 2rem !important;
  }
}

.Toastify__close-button > svg {
  width: 18px !important;
  height: 18px !important;
}
